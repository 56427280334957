.main-footer {
    background: $white;
    position: relative;
    .container {
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        @include prefix(transform, translateY(-5px), moz webkit ms);
    }
}




