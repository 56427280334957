.tilesCard {
    background: $white;
    display: flex;
    padding: 1.5rem;
    @include prefix(border-radius, 1rem, moz webkit ms);
    @include prefix(box-shadow, 0 1rem 1.5rem rgba($color: #AAAAAA, $alpha: 0.06), moz webkit ms);

    @media (max-width:$tablet) {
        margin-bottom: 1rem;
    }

    &__icon {
        $size: 5.4rem;
        width: $size;
        height: $size;
        background: #4791FF;
        @include flexContenCenterInline;
        @include prefix(border-radius, 1rem, moz webkit ms);
        &.green {
            background: #41A838;
        }
    }
    &__info {
        flex-grow: 1;
        padding-left: 1rem;
        h3 {
            margin: 0;
        }
        p {
            margin: 0;
            color: #3F3F3F;
        }
    }
}

.totalBar {
    background: $secondary;
    padding: 1.5rem 2rem;
    @include prefix(border-radius, 0.5rem, moz webkit ms);
    color: $white;
    h4 {
        color: $white;
    }
}

.subHeaderBtns {
    .btn {
        @media (max-width: $mobile) {
            font-size: 12px;
            padding: 0.8rem 0.8rem;
        }
        .plus-icon {
            @media (max-width: $mobile) {
                $size: 1.2rem;
                width: $size;
                height: $size;
                background-size: 1.2rem auto;
                margin-right: 0.3rem !important;
            }
        }
    }
}

.invoiceWrap {
    &__content {
        max-width: 70rem;
        margin: 0 auto;
        padding-top: 10rem;
        &__card {
            
        }
    }
}

.basicInfoCard {
    padding: 3rem 6rem;
    @include prefix(border-radius, 1rem, moz webkit ms);
    @include prefix(box-shadow, 0 0.3rem 0.6rem rgba($color: $black, $alpha: 0.06), moz webkit ms);
    margin-bottom: 2rem;
}