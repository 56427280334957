.accordion {
    &.invoice_acc {
        margin-bottom: 3rem;
        .accordion-item {
            margin-bottom: 1rem;
            @include prefix(border-radius, 0.5rem, moz webkit ms);
            .accordion-header {
                .accordion-button {
                    font-size: $default-font-size;
                    @include prefix(border-radius, 0.5rem, moz webkit ms);
                    .delIcon {
                        position: absolute;
                        right: 3.5rem;
                        top: 8px;
                    }
                    @include shadowNone;
                    &:not(accordion-body) {
                        background-color: $white;
                    }
                }
            }
            .accordion-collapse {
                .accordion-body {
                    padding: 3rem 6rem;
                }
            }
        }
    }
}

.invitePeople {
    i {
        // @include prefix(transition, 0.9s, moz webkit ms);
        transform: rotate(180deg);
    }
    &.collapsed {
        i {
            $size: 1rem;
            width: $size;
            height: $size;
            display: inline-flex;
            transform: rotate(360deg);
        }
    }
}