.plan-bg {
    width: 100%;
    background: url('../images/planbg.jpg') center center no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
    padding: 12rem 0 0 0;
    &::before {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        @include prefix(background, linear-gradient(109deg, rgba(0,0,0,1), rgba(84,6,6,0.35)), moz webkit ms);
        height: 100%;
    }

    &__card {
        background: #EFF1F2;
        @include prefix(border-radius, 1.5rem, moz webkit ms);
        padding: 3rem;
    }
}

.planCard {
    background: $white;
    text-align: center;
    @include prefix(border-radius, 1.5rem, moz webkit ms);
    @include prefix(box-shadow, 0 0.3rem 1.5rem rgba($color: $black, $alpha: 0.18), moz webkit ms);
    &__title {
        text-transform: uppercase;
        padding: 1rem 0 0 0;
    }
    &__mmyy {
        background: #00BDE0;
        padding: 1rem 0;
        color: $white;
        font-size: 3rem;
        font-weight: $weight-semibold;
        width: 106%;
        @include prefix(transform, translateX(-3%), moz webkit ms);
        @include prefix(border-radius, 0.5rem, moz webkit ms);
        small {
            font-size: 2rem;
        }
    }
    &__content {
        padding: 3rem;
        ul {
            margin-bottom: 2rem;
        }
    }

    &.basic {
        .planCard__title {
            color: $secondary;
        }
        .planCard__mmyy {
            background: #00BDE0;
        }
    }
    &.standard {
        .planCard__title {
            color: #B878C0;
        }
        .planCard__mmyy {
            background: #B878C0;
        }
    }
    &.premium {
        .planCard__title {
            color: #F37373;
        }
        .planCard__mmyy {
            background: #F37373;
        }
    }
}