.dashWapper {
    display: flex;
    // padding-right: 6rem;
    &__menu {
        width: 27rem;
        background: $primary;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        padding-top: 8rem;
        z-index: 1;
        @media (max-width:$tablet) {
            left: -100%;
            @include visibilityOpacityHide;
        }

        &.opened {
            width: 30rem;
            left: 0;
            @include visibilityOpacityShow;
        }

        ul {
            padding-top: 1rem;
            li {
                padding-left: 1rem;
                a {
                    display: flex;
                    align-items: center;
                    padding: 1.2rem 1rem;
                    color: $white;

                    .icon {
                        $size: 3rem;
                        width: $size;
                        height: $size;
                        background: url('#{$base-image-path}/iep-menu-sprite.svg') 0 0 no-repeat;
                        margin-right: 0.5rem;

                        &.dashboard {
                            background-position: 0 0;
                        }
                        &.kidsProfile {
                            background-position: -3rem 0;
                        }
                        &.specialCls {
                            background-position: -6rem 0;
                        }
                        &.conductAssess {
                            background-position: -9rem 0;
                        }
                        &.assessmentResults {
                            background-position: -12rem 0;
                        }
                        &.goalBank {
                            background-position: -15rem 0;
                        }
                        &.meetings {
                            background-position: -18rem 0;
                        }
                        &.resourceSection {
                            background-position: -21rem 0;
                        }
                        &.userResponseQry {
                            background-position: -24rem 0;
                        }
                        &.contactUs {
                            background-position: -27rem 0;
                        }
                        &.billing {
                            background-position: -30rem 0;
                        }
                    }
                }

                &:hover {
                    a {
                        background: rgba($color: #000, $alpha: 0.05);
                        @include prefix(border-radius, 3rem 0 0 3rem, moz webkit ms);
                    }
                }

                &.active {
                    a {
                        background: $white;
                        @include prefix(border-radius, 3rem 0 0 3rem, moz webkit ms);
                        color: $primary;
                        .icon {
                            &.dashboard {
                                background-position: 0 -3rem;
                            }
                            &.kidsProfile {
                                background-position: -3rem -3rem;
                            }
                            &.specialCls {
                                background-position: -6rem -3rem;
                            }
                            &.conductAssess {
                                background-position: -9rem -3rem;
                            }
                            &.assessmentResults {
                                background-position: -12rem -3rem;
                            }
                            &.goalBank {
                                background-position: -15rem -3rem;
                            }
                            &.meetings {
                                background-position: -18rem -3rem;
                            }
                            &.resourceSection {
                                background-position: -21rem -3rem;
                            }
                            &.userResponseQry {
                                background-position: -24rem -3rem;
                            }
                            &.contactUs {
                                background-position: -27rem -3rem;
                            }
                            &.billing {
                                background-position: -30rem -3rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &__content {
        flex-grow: 1;
        padding: 8rem 0 4rem 27rem;

        &.blured {
            filter: blur(8px);
            -webkit-filter: blur(8px);
        }

        @media (max-width:$tablet) {
            padding: 8rem 0 4rem 0;
        }

        &__inside {
            padding: 4rem;
            @media (max-width:$mobileBig) {
                padding: 1.5rem;
            }

            &__card {
                background: $white;
                @include prefix(border-radius, 1.5rem, moz webkit ms);
                padding: 1.5rem;
                @include cardShadow;
                &.contactUs {
                    @include prefix(border-radius, 1.5rem 1.5rem 0 0, moz webkit ms);
                }
            }
            &__bot {
                background: #4E4E4E;
                @include prefix(border-radius, 0 0 1.5rem 1.5rem, moz webkit ms);
                padding: 3rem;
                display: flex;
                @media (max-width:$mobileMed) {
                    display: block;
                }
                .half {
                    width: 50%;
                    color: $white;
                    padding-left: 6rem;
                    @media (max-width:$tablet) {
                        padding-left: 2rem;
                    }
                    @media (max-width:$mobileMed) {
                        width: 100%;
                        padding-left: 0;
                    }
                    h4 {
                        color: $white;
                    }
                    address{
                        margin-bottom: 0;
                        display: flex;
                        i {
                            width: 2rem;
                        }
                    }
                    &.contactInfo {
                        border-left: rgba($color: #B7B1B1, $alpha: 0.6) solid 1px;
                        @media (max-width:$mobileMed) {
                            border-left: none;
                            margin-top: 1.5rem;
                        }
                        ul {
                            li {
                                display: flex;
                                margin-bottom: 1rem;
                                i {
                                    width: 3rem;
                                }
                                a {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__tiles {
            
        }
    }
}

.overlayBg {
    @include bgOverlay;
    @include visibilityOpacityHide;
    background-color: rgba($color: $white, $alpha: 0.6);
    @include prefix(filter, blur(8px), moz webkit ms);
    &.opened {
        @include visibilityOpacityShow;
    }
}