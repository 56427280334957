.flex-table {
    &__body {
        .status {
            &.pending {
                color: $primary;
            }
            &.complete {
                color: $success;
            }
            &.cancel {
                color: $error;
            }
        }

        &.scrollSection {
            &.max-h-35 {
                max-height: 35rem;
            }
        }
    }
    &__tr {
        display: flex;
        align-items: center;
        padding: 0 1.5rem;

        @media (max-width:$mobileBig) {
            display: block;
        }

        &.trHead {
            // background: #F5F5F5;
            .td {
                color: #464a53;
                font-family: $font-primary;
                font-weight: $weight-semibold;
                &.gap {
                    padding-left: 5rem;
                }
            }
            @media (max-width:$mobileBig) {
                display: none;
            }

            &.gray {
                background:#F5F5F5;
            }
        }

        &.trRow {
            border-bottom: #f1f1f1 solid 1px;
            // margin-bottom: 0.5rem;
            @media (max-width:$mobileBig) {
                @include prefix(box-shadow, 0 0.3rem 0.6rem rgba($color: $black, $alpha: 0.16), moz webkit ms);
            }
            &:nth-child(odd) {
                background: #EDF2F7;
            }
        }

        .td {
            width: calc(100% / 5);
            padding: 1rem 0.8rem;
            font-size: $small-font-size;
            color: #6a707e;

            .check {
                width: 4.8rem;
                display: inline-block;
            }
            &--fg {
                flex-grow: 1;
            }
            &--xxs {
                flex-basis: 8rem;
            }
            &--xs {
                flex-basis: 10rem;
            }
            &--md {
                flex-basis: 15rem;
            }
            &--lg {
                flex-basis: 20rem;
            }
            &--xlg {
                flex-basis: 25rem;
            }
            &--xxlg {
                flex-basis: 30rem;
            }
            &--xxxlg {
                flex-basis: 35rem;
            }

            &--status {
                text-align: right;
                @media (max-width:$mobileBig) {
                    text-align: left;
                }
            }

            &--valign {
                display: inline-flex;
                align-items: center;
            }

            @media (max-width:$mobileBig) {
                width: 100%;
                &:not(:last-child) {
                    border-bottom: #f1f1f1 solid 1px;
                }
            }

            .oderNo {
                display: block;
            }
            .itemName {
                display: block;
            }
        }
    }

    .mobileLabel {
        font-family: $font-primary;
        font-weight: $weight-bold;
        display: none;
        @media (max-width:$mobileBig) {
            display: block;
        }
    }


    .view-btn {
        $size: 4rem;
        width: $size;
        height: $size;
        background: url('#{$base-image-path}/view-icon.svg') center center no-repeat;
        display: inline-block;
        @include prefix(border-radius, 50%, moz webkit ms);
        @include prefix(transition, 0.3s, moz webkit ms);

        &:hover {
            background-color: $img-placholder-bg;
        }
    }

    &.order-list-table &__tr {
        &.trRow {
            padding:1rem 0 1rem 3rem;
        }
    }

    
}

.user-listing {
    &__loop {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        &__info {
            flex-grow: 1;
            padding-left: 1rem;
        }
    }
}

.uploaded-file {
    background: #F8F8F8;
    border: #D9D2D2 solid 1px;
    @include prefix(border-radius, 0.5rem, moz webkit ms);
    margin-right: 1rem;
    display: inline-flex;
    align-items: center;
    padding: 0 1rem;
}