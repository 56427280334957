// @import {primary_color} from '../../../constants.js';

// COLORS
$primary                        : #F42121;
$primary-light                  : #f73838;
$primary-medium                 : #e41b1b;
$primary-dark                   : #c01414;

$secondary                      : #4791FF;
$secondary-medium               : #2f71d4;
$secondary-light                : #5e9efd;

$tertiary                       : #FF7C81;

$dark                           : #243B6B;
$grey                           : #868B9A;
$success                        : #60bc50; 
$error                          : #FF7C81;

$white                          : #fff;
$black                          : #000;
$link                           : #5983F0;

$body-bg-color                  : #F2F2F2;


$img-placholder-bg              : #f0f3f8;

// Typography colors
$heading-color                  : #232323;
$default-text-color             : #1D1D1D;



$iconBgColor                    : #EDEDED;


// widths


// Status
$online                         : $secondary;
$away                           : #FCAD7A;
$busy                           : red;



// FONT FAMILY
$font-primary            : 'Public Sans', sans-serif;



// FONT SIZES
$tiny-font-size                 : 1.2rem;
$small-font-size                : 1.4rem;
$default-font-size              : 1.6rem; 
$medium-font-size               : 1.8rem; 
$large-font-size                : 2rem;
$big-font-size                  : 2.2rem;

// FONT WEIGHT
$weight-light                   : 300;
$weight-regular                 : 400;
$weight-medium                  : 500;
$weight-semibold                : 600;
$weight-bold                    : 700;
$weight-extrabold               : 800;
$weight-black                   : 900;

// GRID
$grid-width                     : 114rem;
$gutter-vertical                : 8rem;
$gutter-horizontal              : 6rem;
$gutter-horizontal-1            : 1rem;
$gutter-horizontal-2            : 2rem;
$gutter-horizontal-4            : 4rem; 

$gutter-vertical-small          : 6rem;
$gutter-vertical-2              : 2rem;



$scrollbarWidth                 : 0.4rem;
$scrollbar-thumb                : #610303;


// Viewports
$desktop_xxl	:	1600px;
$desktop_xl		:	1440px;
$laptop			:	1366px;
$desktop		:	1200px;
$desktopMedium	:   1199px; 
$desktopSmall	:	1024px;
$tablet			:	992px;
$iPhoneX		:	812px;
$tabletSmall	:	768px;
$mobileBig		:	767px;
$mobileMed		:	600px;
$mobile			:	480px;
$mobileSmall	:	375px;
$mobileExSmall	:	320px;



// Rotate
$mobileSmallRotate          : 480px;
$iphone6_7                    : 750px;
$iphoneXRotate              : 375px;


//Form
$input-border-color         :#dcd9d9;


// Line-Heights
$lineHeight_1half           :1.2;


// Paths
$primary-font-path          : "../webfonts";
$base-image-path            : "../images";