.userIcon {
    $size: 5rem;
    width: $size;
    height: $size;
    @include prefix(border-radius, 50%, moz webkit ms);
    background-color: $img-placholder-bg;
    margin-bottom: 0;
    img {
        @include prefix(border-radius, 50%, moz webkit ms);
    }

    &.userx {
      margin-left: -1.5rem;
      border: #EFEFEF solid 3px;
      background: $white;
      color: #95989A;
      font-weight: $weight-medium;
      font-size: 15px;

      &.secondary {
        border-color: $secondary;
      }

      &.one {
        border-color: #1E85F1;
      }
      &.two {
        border-color: #FF6643;
      }
      &.three {
        border-color: #35C44D;
      }
    }
}

.cardboard {
    background: $white;
    @include prefix(border-radius, 0.4rem, moz webkit ms);

    &.rds {
      @include prefix(border-radius, 1.5rem, moz webkit ms);
    }
}


body {
    &.modal-open {
        padding-right: 17px !important;

        .main-header {
            padding-right: 17px;
        }
    }
}



// tooltip css

.tooltip {
    display: block !important;
    z-index: 10000;
  
    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 5px 10px 4px;
    }
  
    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;
    }
  
    &[x-placement^="top"] {
      margin-bottom: 5px;
  
      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  
    &[x-placement^="bottom"] {
      margin-top: 5px;
  
      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  
    &[x-placement^="right"] {
      margin-left: 5px;
  
      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    &[x-placement^="left"] {
      margin-right: 5px;
  
      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    &.popover {
      $color: #f9f9f9;
  
      .popover-inner {
        background: $color;
        color: black;
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, .1);
      }
  
      .popover-arrow {
        border-color: $color;
      }
    }
  
    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }
  
    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }

.toast-container {
  .toast {
    .toast-message {
      font-size: 1.2rem;
    }
  }
}

.profilePicHolder {
  $size: 12rem;
  width: $size;
  height: $size;
  padding: 5px;
  background: $white;
  @include cardShadow;
  @include prefix(border-radius, 50%, moz webkit ms);
  img {
    width: 100%;
    @include prefix(border-radius, 50%, moz webkit ms);
  }
}

.performance-tiles {
  background: $white;
  border: #E4E9F3 solid 1px;
  @include prefix(border-radius, 1.5rem, moz webkit ms);
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;

  &__info {
    flex-grow: 1;
    padding: 0 0 0 1rem;
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}

.moreOpt {
  $size: 3rem;
  width: $size;
  height: $size;
  position: relative;
  &__icon {
    $size: 3rem;
    width: $size;
    height: $size;
    background: url('#{$base-image-path}/threeDot.svg') center center no-repeat;
    cursor: pointer;
  }
}