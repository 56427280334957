.container {
	max-width: 114rem;
	//max-width: 16rem;
}











.gap-lr {
    padding-left: 4rem;
	padding-right: 4rem;
}



.gutters-35 {
	margin-left: -5%;
	margin-right: -5%;
}

.gutters-2rem {
	margin-left: -1.2rem;
	margin-right: -1.2rem;
}

.gutters-5 {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}