.mainHeader {
    width: 100%;
    height: 8rem;
    z-index: 1;
    position: fixed;
    background: $white;
    @include prefix(box-shadow, 0 0.3rem 0.6rem rgba($color: $black, $alpha: 0.07), moz webkit ms);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 5rem;
    z-index: 2;

    @media (max-width: $mobileBig) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    

    &__logo {
        width: 20rem;
        display: inline-flex;
        align-items: center;

        .desktop-logo {
            @media (max-width: $tablet) {
                display: none;
            }
        }
        .mobile-logo {
            display: none;
            @media (max-width: $tablet) {
                display: block;
            }
        }
    }

    &__rightSide {
        .user-menu {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            &__name {
                margin-right: 1rem;
                margin-left: 1rem;
            }
            &__aro {
                $size : 1rem;
                width: $size;
                height: $size;
                background: url('#{$base-image-path}/down-arrow.svg') 0 0 no-repeat;
            }

            .user-menu-dropdown {
                position: absolute;
                right: 0;
                top: 100%;
                min-width: 10rem;
                background: $white;
                padding: 1rem;
                @include cardShadow;
                @include visibilityOpacityHide;
                @include prefix(border-radius, 1rem, moz webkit ms);
            }

            &:hover {
                .user-menu-dropdown {
                    @include visibilityOpacityShow;
                }
            }
        }
    }

    &.invoiceHeader {
        justify-content: flex-start;
        nav {
            ul {
                li {
                    display: inline-block;
                    a {
                        color: $default-text-color;
                    }
                }
            }
        }
    }
}

.menuBtn {
    width: 3rem;
    height: 2.3rem;
    position: relative;
    border: none;
    background: transparent;
    display: none;
    margin-left: 1rem;
    // position: absolute;
    // right: 1.5rem;
    // top: 2.7rem;

    @media (max-width:$tablet) {
        display: block;
    }

    &__line {
        height: 3px;
        background: #303030;
        position: absolute;
        width: 100%;
        left: 0;
        @include prefix(border-radius, 0.3rem, moz webkit ms);
        &.one {
            top: 0;
        }
        &.two {
            width: 90%;
            top: 10px;
        }
        &.three {
            bottom: 0;
        }
    }
}


.mobileMenu {
    position: fixed;
    height: 100vh;
    width: 31rem;
    left: 0;
    top: 0;
    z-index: 1;
    background: $dark;
}