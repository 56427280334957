.color-white { color:$white !important; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }
.uppercase { text-transform:uppercase; }
.nouppercase { text-transform:none !important; }
.cursor { cursor: pointer; } 

.border-left {
    border-left: #dbdbdb solid 1px;
}




