.scrollSection {
	overflow-y:auto;
	scroll-behavior: smooth;
	overflow-y: auto; 
  	scrollbar-width: thin;
  	scrollbar-color: #343434 #cecece;
    &.unset {
        scroll-behavior: unset;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0.1);
        @include prefix(border-radius, 0.6rem, moz webkit ms);
    }

    &::-webkit-scrollbar {
        width: $scrollbarWidth;
        background-color: rgba(0,0,0,0.1);
        @include prefix(border-radius, 0.6rem, moz webkit ms);
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb;
        @include prefix(border-radius, 0.6rem, moz webkit ms);
    }

    &::-ms-scrollbar-track {
        background-color: rgba(0,0,0,0.1);
        @include prefix(border-radius, 0.6rem, moz webkit ms);
    }

    &::-ms-scrollbar {
        width: $scrollbarWidth;
        background-color: rgba(0,0,0,0.1);
        @include prefix(border-radius, 0.6rem, moz webkit ms);
    }

    &::-ms-scrollbar-thumb {
        background: $scrollbar-thumb;
        @include prefix(border-radius, 0.6rem, moz webkit ms);
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: #330101;
        }
        &::-ms-scrollbar-thumb { 
            background: #330101;
        }
    }
}