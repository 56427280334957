.onboarding {
    background: url('../images/teachers-flow-bg.jpg') center center no-repeat;
    background-size: cover;
    position: relative;

    &.signup {
        background: url('../images/signup-bg.jpg') top center no-repeat;
        background-size: cover;
        .container {
            height: auto;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }

    &::before {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        @include prefix(background, linear-gradient(109deg, rgba(0,0,0,1), rgba(84,6,6,0.35)), moz webkit ms);
        height: 100%;
        
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        position: relative;

        @media (max-width:$tablet) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__logo {
        @media (max-width:$tablet) {
            padding: 2rem 0;
        }
        img {
            max-width: 40rem;
            @media (max-width:$tablet) {
                max-width: 30rem;
            }
            @media (max-width:$mobile) {
                max-width: 80%;
            }
        }
    }

    &__card {
        width: 47rem;
        padding: 3rem;
        @include prefix(border-radius, 2rem, moz webkit ms);
        @include prefix(background, linear-gradient(to bottom, #FFFFFF, #E5F1FF), moz webkit ms);
        border: #707070 solid 1px;

        @media (max-width:$mobile) {
            width: 100%;
        }

        &__header {
            text-align: center;
            margin-bottom: 2rem;
            h2 {
                margin-bottom: 0.5rem;
                font-size: 3rem;
            }
            p {
                font-weight: $weight-medium;
            }
        }

        .form-label {
            font-weight: $weight-light;
        }
    }
}

.dontAcc {
    color: #1D1D1D;
    font-weight: $weight-light;
}

.eyeBtn {
    $size: 4rem;
    width: $size;
    height: $size;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        color: #707070;
        &.fa-eye {
            display: none;
        }
    }
    &.showPassword {
        i {
            &.fa-eye-slash {
                display: none;
            }
            &.fa-eye {
                display: block;
            }
        }
    }
}

.otpFields {
    margin-bottom: 3rem;
    position: relative;
    .form-control {
        width: 6rem;
        height: 6rem;
        @include prefix(border-radius, 0.5rem, moz webkit ms);
        margin: 0 0.5rem;
        font-size: 3rem;
        text-align: center;
        font-weight: bold;

        &.error{
            border-color: red;
        }
    }
    label.error{
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 0.5rem;
        color: red;
    }
}