.nav {
    &.nav-tabs {
        &.default {
            border: $primary solid 1px;
            background: $primary;
            @include prefix(border-radius, 0.5rem, moz webkit ms);
            display: inline-flex;
            margin-bottom: 2rem;
            .nav-item {
                .nav-link {
                    color: $white;
                    font-size: 1.6rem;
                    margin-bottom: 0;
                    border: none;
                    font-family: $font-primary;
                    font-weight: $weight-semibold;
                    padding: 0.6rem 2rem;
                    position: relative;
                    &.active {
                        // border: $primary solid 1px;
                        background: $white;
                        color: $primary;
                        @include prefix(border-radius, 0.5rem, moz webkit ms);
                        &::before {
                            @include contentOjc_lr0;
                            @include centerXOnly;
                            top: auto;
                            bottom: -1rem;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid red;
                        }

                        .iconx {
                            &.student {
                                background-position: 0 -34px;
                            }
                            &.group {
                                background-position: -3rem -34px;
                            }
                        }
                    }

                    .iconx {
                        width: 3rem;
                        height: 2rem;
                        background: url("#{$base-image-path}/sprite.svg") 0 0 no-repeat;
                        &.student {
                            background-position: 0 -3px;
                        }
                        &.group {
                            background-position: -3rem -3px;
                        }
                    }
                }

                &:first-child {
                    .nav-link {
                        @include prefix(border-radius, 0.5rem 0 0 0.5rem, moz webkit ms);
                    }
                }
                &:last-child {
                    .nav-link {
                        @include prefix(border-radius, 0 0.5rem 0.5rem 0, moz webkit ms);
                    }
                }
            }
        }
    }
}