.modal {
    .modal-dialog {
        &.modal-md {
            max-width: 64rem;
        }
        &.modal-xs {
            max-width: 45rem;
        }
        &.modal-xxs {
            max-width: 35rem;
        }
        .modal-content {
            background: $white;
            border-color: $primary;
            @include prefix(border-radius, 1rem, moz webkit ms);
            &.sideGap {
                padding: 3rem 3rem;
            }
            &.modal-white {
                background: $white;
            }
            
            .close {
                $size: 3rem;
                width: $size;
                height: $size;
                background: url('#{$base-image-path}/modal-cross.svg') center center no-repeat;
                position: absolute;
                top: 1rem;
                right: 1rem;
                z-index: 1;
                border: none;
            }

            .modal-body {
                padding: 2rem 3rem;
                .logo-title {
                    text-align: center;
                    margin-bottom: 3rem;
                    &__img {
                        margin-bottom: 1.5rem;
                    }

                    &__name {
                        font-family: $font-primary;
                        font-weight: $weight-bold;
                    }
                }

                .otpFields {
                    margin-bottom: 3rem;
                    .form-control {
                        width: 6rem;
                        height: 6rem;
                        @include prefix(border-radius, 0.5rem, moz webkit ms);
                        margin: 0 0.5rem;
                    }
                }
            }
        }
    }
}

.modal-backdrop {
    background: $white;
}

body {
    &.modal-open {
        .dashWapper {
            @include prefix(filter, blur(8px), moz webkit ms);
        }
    }
}