.custom-form {
    .form-group {
        position: relative;
        margin-bottom: 1.5rem;
        .form-control {
            border: none;
            border-bottom: #7a7470 solid 1px;
            background: transparent;
            padding: 2rem 0;
            @include prefix(border-radius, 0, moz webkit ms);
            outline: none;
            font-size: $small-font-size;
            &:focus {
                @include prefix(box-shadow, none, moz webkit ms);
            }

            &.msg {
                height: 3.4rem;
                resize: none;
            }
        }
        .form-control-placeholder {
            font-size: $small-font-size;
            position: absolute;
            top: 0;
            padding: 7px 0 0 0;
            transition: all 200ms;
            opacity: 0.5;
        }
    }
}



.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
}


.form-control {
    font-size: $small-font-size;
    height: 4.2rem;
    padding: 0.5rem 1.2rem;
    background: $white;
    border-color: #dfdfdf;
    @include prefix(border-radius, 0.5rem, moz webkit ms);
    &.elipse {
		@include prefix(border-radius, 2.5rem, moz webkit ms);
        padding-left: 2rem;
        padding-right: 2rem;
	}
    &.height3 {
        height: 3.6rem;
    }
    &.p-bdr {
        border-color: $primary;
    }

    &.calendar {
        background: url('#{$base-image-path}/calendar-grey-icon.svg') 0.8rem 0.8rem no-repeat;
        padding-left: 3.5rem;
    }
}

.form-select {
    padding-top: 1rem;
    padding-bottom: 0.9rem;
    font-size: $small-font-size;
    border-color: #dfdfdf;
    @include prefix(border-radius, 0.5rem, moz webkit ms);
}

.custom-label {
    color: $default-text-color;
    font-size: 1.3rem;
    margin-bottom: 0.2rem;
    display: block;
}





.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: normal;

    &.inline {
        display: inline;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ {
            .checkmark {
                background-color: $white;
                border-color: $primary;
                &:after {
                    display: block;
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        $size: 2rem;
        height: $size;
        width: $size;
        background-color: $white;
        border: #ced7e7 solid 1px;
        @include prefix(border-radius, 0.3rem, moz webkit ms);

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 6px;
            height: 10px;
            border: solid $primary;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover {
        input ~{
            .checkmark {
                background-color: $white;
                border-color: $primary;
                &:after {
                    border: solid $primary;
                    border-width: 0 3px 3px 0;
                    display: block;
                }
            }
        }
    }
    

    &.round-style {
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ {
                .checkmark {
                    background-color: $primary;
                    border-color: $primary;
                    &:after {
                        display: block;
                        border: solid $white;
                        border-width: 0 3px 3px 0;
                    }
                }
            }
        }

        .checkmark {
            border: #ced7e7 solid 1px;
            @include prefix(border-radius, 50%, moz webkit ms);
    
            &:after {
                content: "";
                position: absolute;
                display: none;
                    left: 9px;
                top: 5px;
                width: 6px;
                height: 10px;
                border: solid $primary;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}
  

  
  
/* custom-radio */
.custom-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.inline {
        display: inline-block;
    }

    &:hover {
        input ~{
            .checkmark {
                background-color: $white;
                border: $success solid 1px;
                &:after {
                    border: solid $success;
                    border-width: 0 3px 3px 0;
                    display: block;
                }
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~{
            .checkmark {
                background-color: $success;
                border: $success solid 1px;
                &:after {
                    display: block;
                    border: solid $white;
                    border-width: 0 3px 3px 0;
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        $size: 2.5rem;
        height: $size;
        width: $size;
        background-color: $white;
        @include prefix(border-radius, 50%, moz webkit ms);
        border: #cecece solid 1px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 6px;
            height: 10px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &.md {
            $size: 2rem;
            height: $size;
            width: $size;
            &:after {
                left: 6px;
                top: 3px;
            }
        }
    }

    &.primary {
        &:hover {
            input ~{
                .checkmark {
                    border: $primary solid 1px;
                    &:after {
                        border: solid $primary;
                        border-width: 0 3px 3px 0;
                    }
                }
            }
        }

        input {
            &:checked ~{
                .checkmark {
                    background-color: $primary;
                    border: $primary solid 1px;
                    &:after {
                        border: solid $white;
                        border-width: 0 3px 3px 0;
                    }
                }
            }
        }

        .checkmark {
            border: $primary solid 1px;
            &:after {
                // border: solid $white;
                // border-width: 0 3px 3px 0;
            }
        }
    }


    &.round-style {
        padding-left: 20px;
        margin-bottom: 0;
        .checkmark {
            &:after {
                top: 5px;
                left: 5px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $primary;
            }
        }

        &:hover {
            input ~{
                .checkmark {
                    &:after {
                        top: 5px;
                        left: 5px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: $primary;
                    }
                }
            }
        }

        &.primary {
            input {
                &:checked ~{
                    .checkmark {
                        background-color: $white;
                        &::after {
                            top: 5px;
                            left: 5px;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: $primary;
                            border: none;
                        }
                    }
                }
            }
        }
    }
  }



  
 

 .error {
     font-size: 1.2rem;
     color: red;
 }


/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;
    margin-bottom: 0;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
  }

  .uploadImg {
    $size : 10rem;
    width: $size;
    height: $size;
    display: inline-block;
    @include flexContenCenterInline;
    margin-bottom: 1rem;
    position: relative;

    &__fig {
        $size : 10rem;
        width: $size;
        height: $size;
        overflow: hidden;
        background: #E2E6F2;
        @include prefix(border-radius, 50%, moz webkit ms);
        position: relative;

        

        img {
            @include centerXY;
        }
    }

    .upload-plus-icon {
        $size : 2.2rem;
        width: $size;
        height: $size;
        background: url('#{$base-image-path}/upload-plus-icon.svg') center center no-repeat $white;
        @include prefix(border-radius, 50%, moz webkit ms);
        @include btnShadow;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 7px;
        border: #BBCDFE solid 1px;
        input[type="file"] {
            display: none;
        }
    }


    

    
  }
















  
 
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    -webkit-transition: .4s;
    transition: .4s;
    border: #D5D5D5 solid 1px;

    &:before {
        position: absolute;
        content: "";
        height: 2rem;
        width: 2rem;
        left: 2px;
        bottom: 2px;
        background-color: #D5D5D5;
        @include prefix(transition, 0.4s, moz webkit ms);
    }
  }
  
  input:checked + .slider {
    border-color: $primary;
    background-color: $white;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }
  
  input:checked + .slider:before {
    background-color: $primary;
    @include prefix(transform, translateX(19px), moz webkit ms);
  }
  
  
  /* Rounded sliders */
  .slider.round {
    @include prefix(border-radius, 3.4rem, moz webkit ms);
  }
  
  .slider.round:before {
    @include prefix(border-radius, 50%, moz webkit ms);
  }


  textarea {
    &.form-control {
        height: auto;
        resize: vertical;
    }
  }

  .uploadFilled {
    border: #dfdfdf solid 1px;
    min-height: 4.2rem;
    @include prefix(border-radius, 0.5rem, moz webkit ms);
    padding: 0 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.calendarSelectIcon {
    position: relative;
    .icon {
        $size: 3rem;
        width: $size;
        height: $size;
        position: absolute;
        left: 0;
        top: 0;
        background: url('#{$base-image-path}/calendar-grey-icon.svg') 0.8rem 1rem no-repeat;
    }
    .form-select {
        padding-left: 3.5rem;
    }
}