.range-block {
    .rangeslider {
        &.rangeslider-horizontal {
            height: 0.6rem;
            @include prefix(box-shadow, none, moz webkit ms);
            margin-bottom: 1rem;
        }

        &__fill {
            background-color: $primary;
            @include prefix(box-shadow, none, moz webkit ms);
        }

        &__handle {
            $size: 1.6rem;
            width: $size;
            height: $size;
            @include prefix(box-shadow, none, moz webkit ms);
            outline: none !important;
            border: none;
            background-color: $primary;
            &:after {
                $size: 1rem;
                width: $size;
                height: $size;
                top: 3px;
                left: 3px;
                background-color: $white;
                @include prefix(box-shadow, none, moz webkit ms);
            }
        }
    }
}