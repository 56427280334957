// Default Heading
.headingBox {
	padding: 2rem 2.5rem;
	background: #f6f6f6;
	border-bottom: 1px solid #e6e8eb;

	&.white { background: white; }
	&.nopadding { padding: 0; }

	&.mb_sm { margin-bottom: 2.5rem; }
	&.mb_md { margin-bottom: 4rem; }
	&.mb_lg { margin-bottom: 8rem; }

	&.left { text-align: left; }

	h1, h2, h3, h4, h5, h6 { 
		margin-bottom: 1rem; 
	}

	p {
		font-size: 1.8rem;
		line-height: 1.7;
		margin-bottom: 0;
	}
}

// Headings
.size60 { 
	font-size: 6rem; 
	line-height: 1;
	@media(max-width:$laptop) { 
		font-size:5rem;
	}
	@media(max-width:$desktopSmall) {
		font-size:4rem;
	}
	@media(max-width:$mobileBig) {
		font-size:4rem;
	}
}

.size50 { 
	font-size: 5rem; 
}

.size45 { 
	font-size: 4.5rem; 
}

.size40 { 
	font-size: 4rem; 
	@media(max-width:$tablet) {
		font-size: 3.6rem;
	}
	@media(max-width:$mobileBig) {
		font-size: 3rem;
	}
}

.size36 { 
	font-size: 3.6rem; 
	
	@media(max-width:$mobileBig) {
		font-size: 2.4rem;
	}
}

.size30 { 
	font-size: 3rem; 
	@media(max-width:$tablet) {
		font-size: 2.4rem;
	}
	@media(max-width:$mobileBig) {
		font-size: 2.2rem;
	}
}

.size24 { 
	font-size: 2.4rem; 
}

.size20 { 
	font-size: 2rem; 
}

.size14 { 
	font-size: 1.4rem; 
}