// Buttons
.btn {
	display: inline-block;
	font-family: $font-primary;
	font-weight: $weight-medium;
	border: none;
	line-height: 1;
	font-size: $default-font-size;
	padding: 1.2rem 2rem;
	@include prefix(border-radius, 0.5rem, moz webkit ms);
	@include prefix(transition, 400ms, moz webkit ms);
	position: relative;
	@include prefix(box-shadow, 0 0.8rem 2.7rem rgba($color: #6c6c6c, $alpha: 0.13), moz webkit ms);

	&.inlineIcon {
		display: inline-flex;
		align-items: center;
	}

	// Rounded
	&.elipse {
		@include prefix(border-radius, 25px, moz webkit ms);
	}
	&.rounded-4 {
		@include prefix(border-radius, 4px, moz webkit ms);
	}
	&.rounded-5 {
		@include prefix(border-radius, 5px, moz webkit ms);
	}

	&.btn-w-25 {
		min-width: 25rem;
		position: relative;
	}
	
	// Buttons Sizes
	&.btn-xss {
		font-size: 1.2rem;
		padding: 0.4rem 1rem;
	}
	&.btn-xs {
		font-size: 1.3rem;
		padding: 0.8rem 1.5rem;
	}
	&.btn-sm {
		font-size: 1.4rem;
		padding: 1.2rem 2rem;
	}

	&.btn-md {
		font-size: 1.8rem;
		padding: 1.2rem 4rem;
	}
	
	&.btn-lg {
		font-size: 1.8rem;
		padding: 1.5rem 4.5rem;
	}
	&.btn-long {
		padding-left: 4.5rem;
		padding-right: 4.5rem;
	}

	&.m-btn {
		@media (max-width: $mobile) {
			font-size: 1.4rem;
			padding: 1rem 1.5rem;
		}
	}

	&.btn-full{
		width: 100%;
	}

	&.btn-primary {
		background-color: $primary !important;
		// &:active,
		// &:focus,
		// &:hover {
		// 	background-color: $primary-dark !important;
		// }
	}
	&.btn-secondary {
		background-color: $secondary;
	}
	&.btn-success {
		background-color: $success;
	}
	&.btn-tertiary {
		background-color: $tertiary;
		color: $white;
	}

	&.btn-primary-ghost {
		background-color: transparent;
		border: $secondary solid 0.2rem;
		color: $secondary;
	}
	
	&.btn-secondary-ghost {
		background-color: transparent;
		border: $primary solid 0.2rem;
		color: $primary;
	}
	
	&.btn-white {
		background-color: $white;
	}
	&.btn-gray {
		background-color: #95989A;
		color: $white;
	}
	&.btn-gray-light {
		// background-color: #EBECEF;
		@include prefix(background, linear-gradient(to bottom, #FFFFFF, #E5F1FF), moz webkit ms);
		color: $heading-color;
		border: #D9D2D2 solid 1px;
	}
	&.btn-back {
		background-color:#D8DDE3;
		color: #5F5F5F;
		border: none!important;
	}

	// social btns
	&.btn-social {
		width: 100%;
		font-size: 1.4rem;
		display: block;
		justify-content: center;
		display: flex;
		align-items: center;
		.socialIcon {
			width: 2.5rem;
		}
	}

	&.btn-fb {
		background-color: #39579B;
		color: $white;
		
	}
	&.btn-apple {
		background-color: #1E1F20;
		color: $white;
		
	}
	&.btn-google {
		background-color: transparent;
		color: #1E1F20;
		border: #ddd solid 0.2rem;
	}

	&.btn-outline-gray {
		background-color: transparent;
		border: #e7e7e7 solid 1px;
		&.dark {
			border-color: #7c7c7c;
		}
	}

	&.btn-outline-secondary {
		background-color: transparent;
		border: $secondary solid 1px;
		color: $secondary;
		&.white {
			background-color: $white;
		}

		&:focus,
		&:hover {
			background-color: $secondary;
			color: $white;
		}
	}
	
	
	&.btn-disconnect {
		background: $success;
		color: $white;
	}
	
	&.btn-connect {
		background: $white;
		color:#666666;
	}
	
	&.label-btn {
		background: #F5F5F5;
		@include prefix(border-radius, 1rem, moz webkit ms);
		border: #DFDFDF solid 1px !important;
		@include prefix(box-shadow, none, moz webkit ms);
		font-family: $font-primary;

		&:focus,
		&:active {
			border: $primary solid 1px !important;
		}
	}

	&.noShadow {
		@include shadowNone;
	}

}


.iconx {
	$size: 3rem;
	width: $size;
	height: $size;
	@include flexContenCenterInline;
	cursor: pointer;
	border: none;

	&.action {
		$size: 2.4rem;
		width: $size;
		height: $size;
		@include prefix(border-radius, 0.3rem, moz webkit ms);
		&.view {
			background: #FF6643;
		}
		&.edit {
			background: #41A838;
		}
		&.delete {
			background: #F42121;
		}
		&.lock {
			background: #19AFC7;
		}
		&.group {
			background: #847DC8;
		}
		&.secondary {
			background: $secondary;
		}
		&.file {
			background: #B7867B;
		}
	}
}


.back-aro {
	$size: 3rem;
	width: $size;
	height: $size;
	background: url("#{$base-image-path}/back-aro.svg") center center no-repeat;
	position: absolute;
	left: 2rem;
	top: 2rem;
	border: none;
	outline: none;
}

.plus-icon {
	$size: 2rem;
	width: $size;
	height: $size;
	background: url("#{$base-image-path}/plus-icon.svg") center center no-repeat;
}

.calendar-icon {
	$size: 2.5rem;
	width: $size;
	height: $size;
	background: url("#{$base-image-path}/calendar-icon.svg") center center no-repeat;
}














